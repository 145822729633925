import Inputmask from "inputmask";

function cepVerify() {
    Inputmask("99999-999").mask("#cep");
    $("#cep").on("blur", async (e) => {
        const value = e.target.value;
        if (!value) {
            return;
        }
        const cep = value.replace(/\D/g, "");

        getAddress(cep);
    });
}

async function getAddress(cep) {
    try {
        const response = await fetch(`/cepwebservice/cep/${cep}`);
        if (!response.ok) {
            console.error("Erro ao buscar CEP:", response.statusText);
            return;
        }

        const resposta = await response.json();
        if ((resposta?.length ?? 0) <= 0) {
            console.warn("CEP não encontrado ou resposta vazia.");
            return;
        }

        const dadosCep = resposta[0];
        console.log($("#logradouro"));
        $("#street").val(dadosCep.logradouro);
        $("#neighborhood").val(dadosCep.bairro);
        $("#city").val(dadosCep.cidade);
        $("#state").val(dadosCep.estado).trigger("change");
    } catch (error) {
        console.error("Erro ao processar o CEP:", error);
    }
}

export { cepVerify };
